.ant-card-body {
  padding: 10px !important;
}

.card_wrapper {
  width: 100%;
  height: 450px;
  overflow-y: auto;
}
.row {
  margin: 0 !important;
}
.col {
  margin-bottom: 20px;
}
.ant-card-meta-title {
  font-size: 30px !important;
}

.Decrip {
  height: 200px;
  overflow: scroll;
}

.Decrip p {
  font-size: 25px;
}
