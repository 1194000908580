.Wrapper {
  display: flex;
  flex-direction: column;
}
.Wrapper h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.Wrapper .input_wrap {
  width: 100%;
}

.Wrapper .scrool {
  width: 100%;
  height: 550px;
  overflow-y: scroll !important;
}

.Wrapper h3 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
}

.Wrapper .row {
  margin: 40px 0 0 0 !important;
}

.Wrapper .col > input,
select {
  width: 100%;
  margin-bottom: 20px;
}
.Wrapper .cols > input,
select {
  width: 100%;
}

.Wrapper input,
select {
  font-family: "Ubuntu", sans-serif;
  background: #ffffff;
  border: 2px solid #eeeeee;
  border-radius: 15px;
  padding: 15px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
}

.Wrapper input:focus {
  border: 2px solid red;
}

.Wrapper input[type="file"] {
  display: none;
}

.Wrapper label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 15px;
  border-style: solid;
  text-align: center;
  padding: 10px 10px 15px 10px;
  cursor: pointer;
}

.Wrapper .spandownload {
  font-size: 80px;
  position: relative;
  top: 10px;
  margin-right: 5px;
}

.Wrapper .spins {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 100%;
  margin-top: 2px;
}

.spinsSecond {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 15px;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 100%;
  margin-top: 2px;
  border: 3px solid #eeeeee;
}

.spinsSecond ion-icon {
  font-size: 130px;
  color: green;
}

.cols {
  margin: 5% 0;
}
