.Wrapper {
  display: flex;
  flex-direction: column;
}
.Wrapper h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.Wrapper .input_wrap {
  width: 100%;
}

.Wrapper .scrool {
  width: 100%;
  overflow-y: scroll;
}

.Wrapper h3 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #fff;
}

.Wrapper .row {
  margin: 40px 0 0 0 !important;
}

.Wrapper .col > input,
select {
  width: 100%;
  margin-bottom: 20px;
}

.Wrapper input,
select {
  font-family: "Ubuntu", sans-serif;
  background: #ffffff;
  border: 2px solid #eeeeee;
  border-radius: 15px;
  padding: 15px;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #aaaaaa;
}

.Wrapper input:focus {
  border: 2px solid red;
}
