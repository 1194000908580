.col > button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  color: #ffffff;
}
.row {
  margin-top: 40px;
}
.col > button > i {
  margin-right: 10px;
  font-size: 20px;
}

.col:nth-child(1) > button {
  background: #03544c;
}
.col:nth-child(2) > button {
  background: rgb(235, 50, 50);
  /* background: rgb(235, 99, 99); */
}
.Wrapper h3 {
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
}
.Wrapper p {
  text-align: center;
  margin-top: 20px;
  color: rgb(235, 99, 99);
}
.Wrapper button {
  padding: 10px 20px;
  font-size: 26px;
  background-color: black;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  cursor: pointer;
}
